import react, { useState, useEffect } from "react";
import { Layout, Menu, message, Space } from "antd";
import {
  SettingFilled,
  DollarCircleFilled,
  ExceptionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";

import Favicon from "../../../images/favicon.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SiderBar = ({ toggle, collapsed }) => {
  const navigate = useNavigate();
  const info = new API.Info();

  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState();
  

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleClick = (address) => {
    navigate(address);
    setActiveMenuItem(address);
  };

  const fetchInfo = (payload) => {
    getDataManager(info?.getInfo, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        setSettings(x?.data);
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
      }
    });
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
      <div className="laststand-logo">
        {collapsed ? (
          <img
            className="collapsed-img"
            src={settings?.logo_url}
            alt=""
            onClick={() => navigate("/content-management/videos")}
          />
        ) : (
          <img
            className="not-collapsed-img"
            src={settings?.logo_url}
            alt=""
            onClick={() => navigate("/content-management/videos")}
          />
        )}
      </div>
      <Menu theme="dark"  mode="inline">
        <SubMenu
          key="1"
          icon={<SettingFilled />}
          title="Content Management"
          style={
            activeMenuItem === "/content-management/videos"
              ? { borderLeft: "6px solid #fff400" }
              : activeMenuItem === "/content-management/announcement"
              ? { borderLeft: "6px solid #fff400" }
              : { borderLeft: "0px" }
          }
        >
          <Menu.Item
            key="1a"
            onClick={() => handleClick("/content-management/videos")}
          >
            Videos
          </Menu.Item>
          {/* <Menu.Item
            key="1b"
            onClick={() => navigate("/content-management/live")}
          >
            Live
          </Menu.Item> */}
          <Menu.Item
            key="1c"
            onClick={() => handleClick("/content-management/announcement")}
          >
            Announcements
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="2"
          icon={<DollarCircleFilled />}
          onClick={() => handleClick("/donations")}
          style={
            activeMenuItem === "/donations"
              ? { borderLeft: "6px solid #fff400" }
              : { borderLeft: "0px" }
          }
        >
          Donations
        </Menu.Item>
        <SubMenu
          key="3"
          icon={<ExceptionOutlined />}
          title="Administration"
          style={
            activeMenuItem === "/administration/users"
              ? { borderLeft: "6px solid #fff400" }
              : activeMenuItem === "/administration/global-settings"
              ? { borderLeft: "6px solid #fff400" }
              : activeMenuItem === "/administration/about-us"
              ? { borderLeft: "6px solid #fff400" }
              : activeMenuItem === "/privacy-policy"
              ? { borderLeft: "6px solid #fff400" }
              : activeMenuItem === "/administrations/terms"
              ? { borderLeft: "6px solid #fff400" }
              : {borderLeft:"0px"}
          }
        >
          <Menu.Item
            key="3a"
            onClick={() => handleClick("/administration/users")}
          >
            Users
          </Menu.Item>
          <Menu.Item
            key="3b"
            onClick={() => handleClick("/administration/global-settings")}
          >
            Global Settings
          </Menu.Item>
          <Menu.Item
            key="3c"
            onClick={() => handleClick("/administration/about-us")}
          >
            About us
          </Menu.Item>
          <Menu.Item key="3d" onClick={() => handleClick("/privacy-policy")}>
            Privacy Policy
          </Menu.Item>
          <Menu.Item
            key="3e"
            onClick={() => handleClick("/administrations/terms")}
          >
            Terms and Conditions
          </Menu.Item>
        </SubMenu>
      </Menu>
      {collapsed ? (
        <p className="copyright-text">©</p>
      ) : (
        <p className="copyright-text">Copyright © 2023 {settings?.site_name}</p>
      )}
    </Sider>
  );
};

export default SiderBar;
