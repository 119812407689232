/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense,useEffect } from "react";
import { Layout, Space, Avatar, Popover } from "antd";
import {
  AlignRightOutlined,
  AlignLeftOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

import Spinner from "../common/spinner";
import SiderBar from "./sidebar";
import { useWindowSize } from "../common/windowsize";
import { setUserData } from "../../utils/redux/actions";

import "./styles.scss";

const Videos = React.lazy(() => import("../content-management/videos"));
const AddVideos = React.lazy(() =>
  import("../content-management/videos/add-videos")
);
const Live = React.lazy(() => import("../content-management/live"));
const AddLive = React.lazy(() => import("../content-management/live/add-live"));
const Announcement = React.lazy(() =>
  import("../content-management/announcement")
);
const AddAnnouncement = React.lazy(() =>
  import("../content-management/announcement/add-anouncement")
);

const Donations = React.lazy(() => import("../donations"));
const TermsandConditions = React.lazy(() =>
  import("../administration/terms-and-conditions")
);
const Users = React.lazy(() => import("../administration/users"));
const AddUser = React.lazy(() => import("../administration/users/add-user/"));
const EditUser = React.lazy(() => import("../administration/users/add-user"));
const PrivacyPolicy = React.lazy(() =>
  import("../administration/privacy-policy")
);
const AboutUs = React.lazy(() => import("../administration/about-us"));
const GlobalSettings = React.lazy(() =>
  import("../administration/global-settings")
);

const Profile = React.lazy(() => import("../admin/profile"));
const ChangePassword = React.lazy(() => import("../admin/password"));

const { Header, Content } = Layout;

const AdminLayout = () => {
  const size = useWindowSize();
  const isSmallScreen = size?.width < 769;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);  
  const [open, setOpen] = useState(false);

  useEffect(() => {
    isSmallScreen?setCollapsed(true):setCollapsed(false)
  }, [isSmallScreen]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const { profileData } = useSelector((state) => {
    return state?.app;
  });
  console.log(profileData)

  const handleChangeLogout = () => {
    navigate("/admin/change-password");
    setOpen(false);
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container" onMouseLeave={()=>setOpen(false)}>
        <div className="profile-opt" onClick={handleProfileClick}>
          <Avatar size={20} src={profileData?.image_url} />
          Profile
        </div>
        <div className="password-opt" onClick={handleChangeLogout}>
          <LoginOutlined />
          Change Password
        </div>
        <div className="logout-opt" onClick={handleLogout}>
          <LoginOutlined />
          Logout
        </div>
      </section>
    );
  };

  const openMenu = () => {
    setOpen(!open);
  };

  const handleProfileClick = () => {
    navigate(`/admin/profile`);
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("last-stand-admin-panel-user-data");
    dispatch(setUserData({}));
    navigate("/login");
  };

  return (
    <Layout>
      <SiderBar toggle={toggle} collapsed={collapsed} />
      <Layout className="site-layout">
        <Header className="site-layout-background bugify-navbar">
          <div className="collapse-btn" onClick={toggle}>
            {collapsed ? (
              <AlignRightOutlined className="trigger" />
            ) : (
              <AlignLeftOutlined className="trigger" />
            )}
          </div>
          <Space align="center" size={20}>
            <Popover
              placement="rightBottom"
              content={userPopover()}
              trigger="click"
              className="user-popover"
              open={open}
            >
              <Avatar
                onClick={openMenu}
                shape="square"
                src={profileData?.image_url}
              />
            </Popover>
          </Space>
        </Header>
        <Content className="site-layout-background content-main-section">
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/content-management/videos" element={<Videos />} />
              <Route path="/live/edit-videos/:id" element={<AddVideos />} />
              <Route path="/add-videos" element={<AddVideos />} />
              <Route path="/content-management/live" element={<Live />} />
              <Route path="/live/edit-live/:id" element={<AddLive />} />
              <Route path="/add-live" element={<AddLive />} />
              <Route
                path="/content-management/announcement"
                element={<Announcement />}
              />
              <Route path="/add-anouncement" element={<AddAnnouncement />} />

              <Route
                path="/announcement/add-anouncement/:id"
                element={<AddAnnouncement />}
              />
              <Route path="/donations" element={<Donations />} />
              <Route
                path="/administrations/terms"
                element={<TermsandConditions />}
              />
              <Route path="/administration/users" element={<Users />} />
              <Route path="/user/add" element={<AddUser />} />
              <Route path="/user/edit/:id" element={<EditUser />} />
              <Route
                path="/administration/global-settings"
                element={<GlobalSettings />}
              />
              <Route path="/admin/profile" element={<Profile />} />
              <Route
                path="/admin/change-password"
                element={<ChangePassword />}
              />

              {/* <Route
                path="/settings/global-settings"
                element={<GlobalSettings />}
              /> */}
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/administration/about-us" element={<AboutUs />} />
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
