/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from "../utils/api";
import { message } from "antd";
import Spinner from "./common/spinner";
import { getDataManager, getErrorMessage } from "../utils/helper.functions";
import AdminLayout from "./layout";
import SignIn from "./auth/sign-in";
import ResetPassword from "./auth/reset-password";
import { setProfieData } from "../utils/redux/actions";
import { setUserData } from "../utils/redux/actions";

import "./App.scss";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = new API.Auth();

  const { userData } = useSelector((state) => {
    return state?.app;
  });

  const savedUserData = JSON.parse(
    localStorage.getItem("last-stand-admin-panel-user-data")
  );

  useEffect(() => {
    dispatch(setUserData(savedUserData));
    fetchUserDetails();
  }, []);

  const [loading, setLoading] = useState(false);

  const fetchUserDetails = () => {
    getDataManager(auth?.getProfile, setLoading).then((x) => {
      if (x?.status === "success") {
        dispatch(setProfieData(x?.data));
        // setImageId(x?.data?.image);
      } 
      // else {
      //   const error = getErrorMessage(x?.errors) || x?.message;
      //   if (x?.message === "Invalid token.") {
      //     return message.error("Your session has expired. Please log in again to continue.");
      //   } else {
      //     message.error({
      //       content: error || "Error ocurred",
      //       duration: 3,
      //     });
      //   }
      // }
    });
  };

  const isLoggedIn = !!savedUserData?.token || !!userData?.token;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return (
    <div className="laststand-main-container">
      {isLoggedIn ? (
        <AdminLayout />
      ) : (
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
