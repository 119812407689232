import Auth from "./Auth";
import Faq from "./Faq";
import Notifications from "./Notifications";
import Banner from "./Banner";
import Messages from "./Messages";
import Terms from "./Terms";
import News from "./News";
import GlobalSettings from "./GlobalSettings";
import FixedPage from "./FixedPage";
import Donations from "./Donations";
import Info from "./Info";

const API = {
  Auth,
  Faq,
  Terms,
  Notifications,
  Banner,
  Messages,
  News,
  GlobalSettings,
  FixedPage,
  Donations,
  Info,
};

export default API;
